/**
 * Gets a link to use on a twitter share button
 *
 * @param {Object} options
 * @param {String} options.text The tweet text body
 * @param {String} options.url The page URL to link
 * @param {String} options.hashtags Comma separated list of hashtags, without # symbol
 * @returns {String} The twitter share URL
 */
export function getTwitterShareLink({ text, url, hashtags }) {
  let twitterLink = "https://twitter.com/intent/tweet"
  if (text) {
    twitterLink += `?text=${encodeURIComponent(text)}`
  }
  if (url) {
    twitterLink += `&url=${encodeURIComponent(url)}`
  }
  if (hashtags && hashtags.length) {
    twitterLink += `&hashtags=${encodeURIComponent(hashtags)}`
  }
  return twitterLink
}

/**
 * Gets a link to use on a facebook share button
 *
 * @param {Object} options
 * @param {String} options.url The page URL to link
 * @param {String} options.hashtag Single string to use a hashtag, without special symbols
 * @returns {String} The facebook share URL
 */
export function getFacebookShareLink({ url, hashtag }) {
  let facebookLink =
    "https://www.facebook.com/dialog/share?app_id=679329702122221&display=popup"
  if (url)
    facebookLink += `&href=${encodeURIComponent(
      url
    )}&redirect_uri=${encodeURIComponent(url)}`
  if (hashtag) facebookLink += `&hashtag=${encodeURIComponent(`#${hashtag}`)}`
  return facebookLink
}

/**
 * Gets a link to use on a whatsapp share button
 *
 * @param {Object} options
 * @param {String} options.text The message content
 * @returns {String} The whatsapp share URL
 */
export function getWhatsappShareLink({ text }) {
  return `https://wa.me/?text=${encodeURIComponent(text)}`
}
