import React, { useRef, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Carousel, Row } from "react-bootstrap"
import Slider from "react-slick"
import "../dist/css/slick.css"
import Img from "gatsby-image"
import left from "../images/left.svg"
import right from "../images/right.svg"
import time from "../images/time.png"
import twitter from "../images/edtw.svg"
import facebook from "../images/edfb.svg"
import alum1 from "../images/alum1.png"
import alum2 from "../images/alum2.png"
import alum3 from "../images/alum3.png"
import alum4 from "../images/alum4.png"
import alum5 from "../images/alum5.png"
import mobalum1 from "../images/mobalum1.png"
import mobalum2 from "../images/mobalum2.png"
import mobalum3 from "../images/mobalum3.png"
import cbdots1 from "../images/dots/wedob/1.png"
import cbdots2 from "../images/dots/wedob/2.png"
import cbdots3 from "../images/dots/wedob/3.png"
import ReactMarkdown from "react-markdown"
import AOS from "aos"
import "aos/dist/aos.css"
import { getFacebookShareLink, getTwitterShareLink } from "../utils/socialLinks"
const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { templateKey: { eq: "index" } }) {
        id
        frontmatter {
          title
          banner {
            heading
            butnLink
            butnTxt
            description
            deskBanner {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            mobBanner {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          impact {
            sectionHeading
            newsArticles {
              articleBy
              articleDate(fromNow: true)
              articleDescription
              articleHeading
              articleLink
            }
          }
          takeActions {
            actionHeading
            actionLink
            description
            actionIcon {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          edCrisis {
            description
            edArticle {
              articleLink
              description
              fbLink
              title
              titleColor
              twLink
              image {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            edLink
            heading
            subHeading
            edImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fellowship {
            heading
            subHeading
            joinLink
            description
            descriptionList {
              description
              dlContent
            }
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          felSpeak {
            description
            felname
            image {
              childImageSharp {
                fixed(cropFocus: NORTH, height: 525, width: 700) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          mission {
            heading
            subHeading
            cards {
              cardLink
              description
              title
              image {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          alumni {
            cardLink
            heading
            subHeading
          }
          wn {
            heading
            wnItem {
              heading
              description
              wnButton {
                butnTxt
                butnLink
                butnColor
              }
              image {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              mobimg {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          instagram {
            Heading
            butnLink
            insContent {
              image {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              link
            }
          }
        }
      }
    }
  `)
  const settingsImpact = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    rows: 2,
    slidesPerRow: 1,
  }
  const settingsBanner = {
    dots: true,
    dotsClass: "slick-dots fel-indi ban-indi",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  }
  const settingsFel = {
    dots: true,
    dotsClass: "slick-dots fel-indi",
    infinite: true,
    fade: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  }
  const settingsEdCar = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    autoplaySpeed: 3000,
  }
  const settingsEdCarMob = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: false,
    adaptiveHeight: true,
    rows: 3,
    slidesPerRow: 1,
  }
  useEffect(() => {
    AOS.init()
    AOS.refresh()
  }, [])
  const bannerCar = useRef()
  const impactCar = useRef()
  const edCar = useRef()
  const edCarMob = useRef()
  const fellowCar = useRef()
  const impactPrevious = () => {
    impactCar.current.slickPrev()
  }
  const impactNext = () => {
    impactCar.current.slickNext()
  }
  const edCarPrev = () => {
    edCar.current.slickPrev()
  }
  const edCarNext = () => {
    edCar.current.slickNext()
  }
  const edCarMobPrev = () => {
    edCarMob.current.slickPrev()
  }
  const edCarMobNext = () => {
    edCarMob.current.slickNext()
  }
  var aosdelay = 0
  return (
    <Layout pageName="Home">
      <SEO title="Home" />
      <div className="w-100">
        <Row className="mx-0">
          <div className="col-lg-8 px-0">
            <Slider {...settingsBanner} ref={bannerCar}>
              {data.markdownRemark.frontmatter.banner.map(carItem => (
                <Carousel.Item key={carItem.heading} className="bg-ter">
                  <div className="carousel-fill">
                    <Img
                      fluid={carItem.deskBanner.childImageSharp.fluid}
                      loading="eager"
                      placeholderStyle={{ visibility: "hidden" }}
                      alt="Desktop Banner"
                      className="w-100 d-none d-lg-block"
                    />
                    <Img
                      fluid={carItem.mobBanner.childImageSharp.fluid}
                      alt="Mobile Banner"
                      className="d-block d-lg-none w-100"
                    />
                    <Carousel.Caption className="text-left ban-cap">
                      <h3>{carItem.heading}</h3>
                      <hr />
                      <ReactMarkdown className="mb-lg-5 mb-4 gentxt">
                        {carItem.description}
                      </ReactMarkdown>
                      <Link
                        to={carItem.butnLink}
                        className="butn butn-main mr-tbutn butn-sm-sp"
                      >
                        {carItem.butnTxt}
                      </Link>
                    </Carousel.Caption>
                  </div>
                </Carousel.Item>
              ))}
            </Slider>
          </div>
          <div className="col-lg-4 ban-right p-5">
            <div className="rban">
              <div className="ban-heading pb-lg-4">
                <Link className="h4link" to="/media">
                  <h4>
                    {data.markdownRemark.frontmatter.impact.sectionHeading}
                  </h4>
                </Link>
                <div className="car-indi d-none d-lg-flex">
                  <div className="left" onClick={impactPrevious}>
                    <img src={left} />
                  </div>
                  <div className="right" onClick={impactNext}>
                    <img src={right} />
                  </div>
                </div>
              </div>
              <div>
                <Slider
                  {...settingsImpact}
                  ref={impactCar}
                  className="impact-car"
                  id="impact-car"
                >
                  {data.markdownRemark.frontmatter.impact.newsArticles.map(
                    impItem => (
                      <Link
                        to={impItem.articleLink}
                        className="ban-cont"
                        target="_blank"
                        rel="noreferrer noopener"
                        key={impItem.articleHeading}
                      >
                        <div className="ban-cont">
                          <div className="news-cont">
                            <div className="news-head">{impItem.articleBy}</div>
                            <div className="news-title">
                              {impItem.articleHeading}
                            </div>
                            <div className="news-body">
                              <ReactMarkdown>
                                {impItem.articleDescription}
                              </ReactMarkdown>
                            </div>
                            <div className="news-date">
                              <img src={time} alt="Time Icon" />
                              <span>{impItem.articleDate}</span>
                            </div>
                          </div>
                        </div>
                      </Link>
                    )
                  )}
                </Slider>
              </div>
              <div>
                <div className="car-indi d-flex d-lg-none max-50 mx-auto">
                  <div className="left" onClick={impactPrevious}>
                    <img src={left} />
                  </div>
                  <div className="right" onClick={impactNext}>
                    <img src={right} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </div>
      <div className="container pt-3 pb-4">
        <Row className="mx-0 py-5">
          {data.markdownRemark.frontmatter.takeActions.map(takeAction => (
            <div
              className="col-lg-3 px-0 pr-lg-5 action-card"
              data-aos="fade-up"
              data-aos-delay={(aosdelay += 100)}
              data-aos-duration="1000"
              key={takeAction.actionHeading}
            >
              <Img
                className="mr-3 mr-lg-0 action-icon"
                fluid={takeAction.actionIcon.childImageSharp.fluid}
                alt={takeAction.actionHeading}
              />
              <div className="ac-cont">
                <p className="ac-head">{takeAction.actionHeading}</p>
                <p className="ac-txt">{takeAction.description}</p>
                <Link to={takeAction.actionLink} className="ac-link">
                  Take Action
                </Link>
              </div>
            </div>
          ))}
        </Row>
      </div>
      <div className="w-100 bg-sec py-5 d-none d-lg-block">
        <Container className="py-5">
          <Row className="mx-0">
            <div className="col-lg-3 align-center">
              <div className="ed">
                <p
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                  className="ed-title"
                >
                  {data.markdownRemark.frontmatter.edCrisis.subHeading}
                </p>
                <p
                  data-aos="fade-up"
                  data-aos-delay="300"
                  data-aos-duration="1000"
                  className="ed-head"
                >
                  {data.markdownRemark.frontmatter.edCrisis.heading}
                </p>
                <p
                  data-aos="fade-up"
                  data-aos-delay="600"
                  data-aos-duration="1000"
                  className="ed-txt"
                >
                  {data.markdownRemark.frontmatter.edCrisis.description}
                </p>
                <Link
                  data-aos="fade-up"
                  data-aos-delay="900"
                  data-aos-duration="1000"
                  to={data.markdownRemark.frontmatter.edCrisis.edLink}
                  className="butn butn-main"
                >
                  Learn more
                </Link>
              </div>
            </div>
            <div
              className="col-lg-9 px-lg-5 alsoCarNav"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1000"
            >
              <div className="car-indi desk">
                <div className="left" onClick={edCarPrev}>
                  <img src={left} />
                </div>
                <div className="right" onClick={edCarNext}>
                  <img src={right} />
                </div>
              </div>
              <Slider ref={edCar} {...settingsEdCar} className="ed-car ">
                {data.markdownRemark.frontmatter.edCrisis.edArticle.map(
                  edArt => (
                    <div className="ed-car-item" key={edArt.title}>
                      <Img
                        fluid={edArt.image.childImageSharp.fluid}
                        className="ed-car-img"
                        alt={edArt.title}
                      />
                      <div className="ed-content">
                        <div className={edArt.titleColor}>
                          <p className="ed-car-title">{edArt.title}</p>
                        </div>
                        <p className="ed-car-txt">{edArt.description}</p>
                      </div>
                      <div className="ed-links">
                        <a
                          href={getTwitterShareLink({
                            text: `${edArt.title} ${edArt.description}`,
                            url: edArt.articleLink,
                            hashtags: "edCrisis",
                          })}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={twitter} className="ed-social" />
                        </a>
                        <a
                          href={getFacebookShareLink({
                            url: edArt.articleLink,
                            hashtag: "edCrisis",
                          })}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={facebook} className="ed-social" />
                        </a>
                      </div>
                      <Link
                        to={edArt.articleLink}
                        target="_blank"
                        rel="noreferrer noopener"
                        className="ed-social ac-link ed-soc"
                      >
                        <span>Read&nbsp;More</span>
                      </Link>
                    </div>
                  )
                )}
              </Slider>
            </div>
          </Row>
        </Container>
      </div>
      <div className="edcarcont mobile w-100 bg-sec py-5 d-block d-lg-none">
        <Container>
          <div className="ed">
            <p
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1000"
              className="ed-title"
            >
              Covid related Ed-crisis
            </p>
            <p
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-duration="1000"
              className="ed-head"
            >
              {data.markdownRemark.frontmatter.edCrisis.heading}
            </p>
            <p
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-duration="1000"
              className="ed-txt"
            >
              {data.markdownRemark.frontmatter.edCrisis.description}
            </p>
          </div>
          <div
            className="col-12 px-0 pb-4"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            <Img
              fluid={
                data.markdownRemark.frontmatter.edCrisis.edImage.childImageSharp
                  .fluid
              }
              alt="Ed Crisis Image round-border"
              className="w-100"
            />
          </div>
          <div
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
            className="alsoCarNav "
          >
            <Slider ref={edCarMob} {...settingsEdCarMob}>
              {data.markdownRemark.frontmatter.edCrisis.edArticle.map(mobEd => (
                <div className="ed-car-item" key={mobEd.title}>
                  <Img
                    fluid={mobEd.image.childImageSharp.fluid}
                    className="ed-car-img"
                    alt={mobEd.title}
                  />
                  <div className="ed-content">
                    <p className={`ed-car-title ${mobEd.titleColor}`}>
                      {mobEd.title}
                    </p>
                    <p className="ed-car-txt">{mobEd.description}</p>
                  </div>
                  <div className="ed-links">
                    <a
                      href={getTwitterShareLink({
                        text: `${mobEd.title} ${mobEd.description}`,
                        url: mobEd.articleLink,
                        hashtags: "edCrisis",
                      })}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={twitter} className="ed-social" />
                    </a>
                    <a
                      href={getFacebookShareLink({
                        url: mobEd.articleLink,
                        hashtag: "edCrisis",
                      })}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={facebook} className="ed-social" />
                    </a>
                  </div>
                </div>
              ))}
            </Slider>
            <div className="car-indi mob my-3">
              <div className="left" onClick={edCarMobPrev}>
                <img src={left} />
              </div>
              <div className="right" onClick={edCarMobNext}>
                <img src={right} />
              </div>
            </div>
            <div
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1000"
              className="text-center"
            >
              <Link
                to={data.markdownRemark.frontmatter.edCrisis.edLink}
                className="butn butn-main"
              >
                Learn more
              </Link>
            </div>
          </div>
        </Container>
      </div>
      <Container className="py-5 mb-5">
        <div className="text-center d-none d-lg-block pb-5">
          <p
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
            className="fel-sub"
          >
            {data.markdownRemark.frontmatter.fellowship.subHeading}
          </p>
          <p
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-duration="1000"
            className="fel-title"
          >
            {data.markdownRemark.frontmatter.fellowship.heading}
          </p>
          <Link
            to={data.markdownRemark.frontmatter.fellowship.joinLink}
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-duration="1000"
            className="fel-butn butn butn-main"
          >
            Know more
          </Link>
        </div>
        <Row>
          <div
            data-aos="fade"
            data-aos-delay="100"
            data-aos-duration="1000"
            className="col-lg-8 px-3 pr-lg-4"
          >
            <Img
              fluid={
                data.markdownRemark.frontmatter.fellowship.image.childImageSharp
                  .fluid
              }
              alt="Fellows Image"
              className="w-100 round-border"
            />
          </div>
          <div className="fel-head-sub text-center d-block d-lg-none py-4">
            <p
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1000"
              className="fel-sub"
            >
              {data.markdownRemark.frontmatter.fellowship.subHeading}
            </p>
            <p
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-duration="1000"
              className="fel-title"
            >
              {data.markdownRemark.frontmatter.fellowship.heading}
            </p>
            <Link
              to={data.markdownRemark.frontmatter.fellowship.joinLink}
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-duration="1000"
              className="fel-butn butn butn-main butnxl"
            >
              Know more
            </Link>
          </div>
          <div className="col-lg-4 pt-3">
            <p
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1000"
              className="pt-2 fel-txt"
            >
              {
                data.markdownRemark.frontmatter.fellowship.descriptionList
                  .description
              }
            </p>
            <ul className="fel-ul" data-reset-sal-delay={(aosdelay += 0)}>
              {data.markdownRemark.frontmatter.fellowship.descriptionList.dlContent.map(
                dlCont => (
                  <li
                    data-aos="fade-up"
                    data-aos-delay={(aosdelay += 100)}
                    data-aos-duration="1000"
                    key={dlCont}
                  >
                    {dlCont}
                  </li>
                )
              )}
            </ul>
          </div>
          <div className="text-center py-lg-5 py-4">
            <p
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1000"
              className="fel-txt-center"
            >
              {data.markdownRemark.frontmatter.fellowship.description}
            </p>
          </div>
        </Row>
        <div
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1000"
          className="fel-car-container"
        >
          <Slider {...settingsFel} ref={fellowCar}>
            {data.markdownRemark.frontmatter.felSpeak.map(felSpk => (
              <div className="fel-car py-5" key={felSpk.felname}>
                <div className="fel-cont">
                  <p className="fel-car-txt">{felSpk.description}</p>
                  <p className="fel-car-author">{felSpk.felname}</p>
                </div>
                <div className="fel-img-cont">
                  <Img
                    fixed={felSpk.image.childImageSharp.fixed}
                    alt="Fellow Image"
                    className="fel-img w-100 round-border"
                  />
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div className="text-center text-lg-right">
          <Link
            to="/how-to-join"
            className="butn butn-main butnxl"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            Join
          </Link>
        </div>
      </Container>
      <div className="bg-sec w-100 py-5">
        <Container className="pb-5">
          <div className="py-5 text-center">
            <div className="max-70 max-sm-100 mx-auto">
              <p
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-duration="1000"
                className="mv-title"
              >
                Our Vision
              </p>
              <div
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-duration="1000"
              >
                <p className="mv-head mb-3">
                  One day all children will attain an excellent education
                </p>
              </div>
            </div>
            <div className="max-50 max-sm-100 mx-auto wedoColorCont">
              <img
                src={cbdots1}
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-duration="1000"
                className="w-100 wedoColorPatch"
              />
              <img
                src={cbdots2}
                data-aos="fade-up"
                data-aos-delay="300"
                data-aos-duration="1000"
                className="w-100 wedoColorPatch"
              />
              <img
                src={cbdots3}
                data-aos="fade-up"
                data-aos-delay="600"
                data-aos-duration="1000"
                className="w-100 wedoColorPatch"
              />
            </div>
            <div className="max-70 max-sm-100 mx-auto pb-lg-5">
              <p
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-duration="1000"
                className="mv-title"
              >
                Our Mission
              </p>
              <div
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-duration="1000"
              >
                <p className="mv-head mb-3">
                  To build a movement of leaders to eliminate educational
                  inequity
                </p>
              </div>
            </div>
          </div>

          <Row className="pb-lg-5" data-reset-sal-delay={(aosdelay = 0)}>
            {data.markdownRemark.frontmatter.mission.cards.map(card => (
              <div className="col-lg-4 col-md-6" key={card.title}>
                <div
                  data-aos="fade"
                  data-aos-delay={(aosdelay += 100)}
                  data-aos-duration="1000"
                  className="mv-card"
                >
                  <Img
                    fluid={card.image.childImageSharp.fluid}
                    alt="Card Image"
                  />
                  <p className="mv-card-title">{card.title}</p>
                  <ReactMarkdown className="mv-card-txt">
                    {card.description}
                  </ReactMarkdown>
                  <Link to={card.cardLink} className="ac-link mv-card-link">
                    Read More
                  </Link>
                </div>
              </div>
            ))}
          </Row>
        </Container>
      </div>
      <Container className="alum-cont px-0 container-md-100">
        <div className="alum-img d-block d-md-none">
          <img
            src={mobalum1}
            alt=""
            data-aos="fade-up"
            data-aos-duration="1300"
            data-aos-delay="100"
            className="alummob d-block d-md-none w-100"
          />
          <img
            src={mobalum2}
            alt=""
            data-aos="fade-up"
            data-aos-duration="1300"
            data-aos-delay="500"
            className="alummob d-block d-md-none w-100"
          />
          <img
            src={mobalum3}
            alt=""
            data-aos="fade-up"
            data-aos-duration="1300"
            data-aos-delay="1000"
            className="alummob d-block d-md-none w-100"
          />
        </div>
        <div className="alum-img d-none d-md-block">
          <img
            src={alum1}
            alt=""
            data-aos="fade"
            data-aos-duration="3000"
            data-aos-delay="100"
            className="alumdesk d-none d-md-block w-100"
          />
          <img
            src={alum2}
            alt=""
            data-aos="fade-up"
            data-aos-duration="1300"
            data-aos-delay="600"
            className="alumdesk d-none d-md-block w-100"
          />
          <img
            src={alum3}
            alt=""
            data-aos="fade-up"
            data-aos-duration="1300"
            data-aos-delay="1000"
            className="alumdesk d-none d-md-block w-100"
          />
          <img
            src={alum4}
            alt=""
            data-aos="fade-up"
            data-aos-duration="1300"
            data-aos-delay="1500"
            className="alumdesk d-none d-md-block w-100"
          />
          <img
            src={alum5}
            alt=""
            data-aos="fade-up"
            data-aos-duration="1300"
            data-aos-delay="1800"
            className="alumdesk d-none d-md-block w-100"
          />
        </div>
        <div className="alum">
          <p data-aos="fade-up" data-aos-duration="1000" className="am-title">
            {data.markdownRemark.frontmatter.alumni.subHeading}
          </p>
          <p
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-duration="1000"
            className="am-head"
          >
            {data.markdownRemark.frontmatter.alumni.heading}
          </p>
          <Link
            to={data.markdownRemark.frontmatter.alumni.cardLink}
            className="alumni butn butn-main"
            data-aos="fade-up"
            data-aos-delay="400"
            data-aos-duration="1000"
          >
            Download Report
          </Link>
        </div>
      </Container>
      <div className="bg-sec pt-5 pb-5">
        <Container>
          <p className="wn-head">
            {data.markdownRemark.frontmatter.wn.heading}
          </p>
          {data.markdownRemark.frontmatter.wn.wnItem.map(wni => (
            <Row className="py-3 py-lg-5 whatsnew" key={wni.heading}>
              <div
                data-aos="fade-up"
                data-aos-duration="1000"
                className="col-lg-7 pr-5 wn-img wncen"
              >
                <Img
                  fluid={wni.image.childImageSharp.fluid}
                  alt={wni.heading}
                  className="w-100 d-none d-lg-block round-border"
                />
                <Img
                  fluid={wni.mobimg.childImageSharp.fluid}
                  alt={wni.heading}
                  className="w-100 d-block d-lg-none round-border"
                />
              </div>
              <div className="col-lg-5 wn-cont">
                <p
                  data-aos="fade-up"
                  data-aos-delay="300"
                  data-aos-duration="1000"
                  className="wn-title"
                >
                  {wni.heading}
                </p>
                <p
                  data-aos="fade-up"
                  data-aos-delay="400"
                  data-aos-duration="1000"
                  className="wn-txt"
                >
                  {wni.description}
                </p>
                <Link
                  to={wni.wnButton.butnLink}
                  data-aos="fade-up"
                  data-aos-delay="500"
                  data-aos-duration="1000"
                  className={`butn butn-main butn-sm-sp`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {wni.wnButton.butnTxt}
                </Link>
              </div>
            </Row>
          ))}
        </Container>
      </div>
    </Layout>
  )
}

export default IndexPage
